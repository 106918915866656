import NavItem from '@hyex/ui/nav/NavItem';
import NavSubmenuItem from '@hyex/ui/nav/NavSubmenuItem';
import Link from 'next/link';
import Image from 'next/image';
import ProductImage from '../Product/common/ProductImage';
import { useState } from 'react';

type NavigationItemType<T, K> = {
  href: string;
  title: string;
  subTitle: string;
  items?: T[];
  subItems?: K[];
  subHref?: string;
  className?: string;
  indexHref?: string;
  viewAllText?: string;
};

function SubmenuItem<T, K>(item: NavigationItemType<T, K>) {
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null);

  const productList = (subItem: any) => (
    <div className="product-item">
      <Link href={`${item.subHref}/${subItem.sku}`} prefetch={false}>
        {/* <ProductImage
          alt={subItem.title}
          sku={subItem.sku}
          className="product-image"
          onSale={subItem?.isOnSale ?? false}
          image={subItem.images.single}
          width={150}
          height={280}
          // TODO: Need more time to use ProductImage, but we need it (sale etc)
        /> */}
        <Image
          alt={subItem.title}
          src={subItem.images.single}
          width={755}
          height={280}
          quality="70"
          className="product-image"
        />
      </Link>
    </div>
  );

  const articleList = (subItem: any) => (
    <div className="nav-article-img">
      <Link href={`${item.subHref}/${subItem.slug}`} prefetch={false}>
        <Image
          alt={`Read ${subItem.title} ${subItem?.product ? subItem?.product.title : ''} `}
          src={subItem.image}
          width={755}
          height={480}
          quality="70"
        />
      </Link>
    </div>
  );

  const navigationList = (subItems: K[]) => (
    <div className="nav-last-articles">
      {subItems.map((subItem: any) => {
        return (
          <div className="nav-article" key={subItem.id}>
            {subItem.images && productList(subItem)}

            {subItem.image && articleList(subItem)}

            <div className={`nav-article-text ${subItem.images ? 'center' : ''}`}>
              <h3>
                <Link
                  href={`${
                    subItem.images
                      ? `${item.subHref}/${subItem.sku}`
                      : `${item.subHref}/${subItem.slug}`
                  } `}
                  prefetch={false}
                >
                  {subItem.title}
                </Link>
              </h3>
            </div>
          </div>
        );
      })}
    </div>
  );

  const mainCategories = item.items?.filter((subItem: any) => !subItem.isSubcategory) || [];
  const subcategories = item.items?.filter((subItem: any) => subItem.isSubcategory) || [];

  return (
    <NavSubmenuItem title={item.title} href={item.indexHref}>
      <div className="nav-full-width">
        <div className="large-block">
          <div className="small-block">
            <NavItem href={item.indexHref}>
              <h4>{item.subTitle}</h4>
            </NavItem>
            <div className="links-block">
              {mainCategories.map((subItem: any) => {
                const categorySubcategories = subcategories.filter(
                  (sub: any) => sub.parentCategory === subItem.value
                );
                return (
                  <div
                    key={subItem.name || subItem.title}
                    className="category-item"
                    onMouseEnter={() => setHoveredCategory(subItem.value)}
                    onMouseLeave={() => setHoveredCategory(null)}
                  >
                    <NavItem href={`${item.href}/${subItem.value}`}>
                      {subItem.name || subItem.title}
                    </NavItem>
                    {hoveredCategory === subItem.value && categorySubcategories.length > 0 && (
                      <div className="subcategories">
                        {categorySubcategories.map((subcategory: any) => (
                          <NavItem
                            key={subcategory.name || subcategory.title}
                            href={`${item.href}/${subcategory.value}`}
                          >
                            {subcategory.name || subcategory.title}
                          </NavItem>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
              <NavItem href={item.indexHref !== null ? item.indexHref : item.href}>
                <span className="underline">
                  {item.viewAllText ? item.viewAllText : 'View all'}
                </span>
              </NavItem>
            </div>
          </div>
        </div>
        {navigationList(item.subItems || [])}
      </div>
    </NavSubmenuItem>
  );
}

export default SubmenuItem;
